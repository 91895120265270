<template>
  <div>
    <v-tooltip right v-if="iconOnly">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="btn btn-outline-primary" @click="handleOpen()" v-bind="attrs" v-on="on">
          <i class="fa-solid fa-file-arrow-up"></i>
        </v-btn>
      </template>
      <span>Upload Dokumen PIB</span>
    </v-tooltip>
    <v-btn class="btn btn-primary" @click="handleOpen()" v-if="!iconOnly">
      <i class="fa-solid fa-file-arrow-up"></i>
      <span class="small ms-2">Upload</span>
    </v-btn>
    <v-dialog v-model="showModal" persistent max-width="800">
      <v-form class="card" ref="initialForm" @submit.prevent="handleSubmit">
        <div class="card-header py-3">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-weight-medium">Form Upload Dokumen</span>
            <v-btn icon class="" @click="handleClose">
              <i class="fa-solid fa-xmark"></i>
            </v-btn>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <label class="fs-14 mb-2">File Dokumen</label>
              <v-file-input
                outlined
                dense
                placeholder="File Dokumen"
                v-model="file"
                :rules="[
                  (value) => {
                    return genericRequiredRule(value, 'File Dokumen');
                  },
                ]"
              />
            </div>
            <div class="col-md-6">
              <label class="fs-14 mb-2">No. Dokumen</label>
              <v-text-field
                outlined
                dense
                placeholder="No. Dokumen"
                v-model="document_number"
                :rules="[
                  (value) => {
                    return genericRequiredRule(value, 'No. Dokumen');
                  },
                ]"
              />
            </div>
            <div class="col-md-6">
              <label class="fs-14 mb-2">Jenis Dokumen</label>
              <v-select
                outlined
                dense
                :items="list_jenis"
                item-text="nama"
                item-value="id"
                placeholder="Jenis Dokumen"
                v-model="document_id"
                :rules="[
                  (value) => {
                    return genericRequiredRule(value, 'Jenis Dokumen');
                  },
                ]"
              />
            </div>
            <div class="col-md-6">
              <label class="fs-14 mb-2">Tanggal Dokumen</label>
              <date-picker
                v-model="document_date"
                :rules="[
                  (value) => {
                    return genericRequiredRule(value, 'Tanggal Dokumen');
                  },
                ]"
              />
            </div>
            <div class="col-md-6">
              <label class="fs-14 mb-2">Dokumen Expired</label>
              <date-picker
                v-model="document_expire"
                :rules="[
                  (value) => {
                    return genericRequiredRule(value, 'Dokumen Expired');
                  },
                ]"
              />
            </div>
            <div class="col-md-6">
              <label class="fs-14 mb-2">Keterangan</label>
              <v-text-field
                outlined
                dense
                placeholder="Keterangan"
                v-model="keterangan"
                :rules="[
                  (value) => {
                    return genericRequiredRule(value, 'Keterangan');
                  },
                ]"
              />
            </div>
            <div class="col-md-6">
              <label class="fs-14 mb-2">Catatan</label>
              <v-text-field
                outlined
                dense
                placeholder="Catatan"
                v-model="document_issued"
                :rules="[
                  (value) => {
                    return genericRequiredRule(value, 'Catatan');
                  },
                ]"
              />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-end">
            <v-btn class="btn btn-secondary mr-2" @click="handleClose"> Batal </v-btn>
            <v-btn type="submit" class="btn btn-primary" :loading="isLoading">Upload</v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import format3Digit from "@/utils/format-3digit";
import { ValidationRules } from "@/mixins/validation-rules.js";

export default {
  name: "DokumenForm",
  mixins: [ValidationRules],
  props: {
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DatePicker: () => import("@/components/atoms/date-picker"),
  },
  data() {
    return {
      format3Digit,
      showModal: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.dokumen.isLoading;
    },
    list_jenis() {
      return this.$store.state.dokumen.list_jenis;
    },
    document_number: {
      get() {
        return this.$store.state.dokumen.form.document_number;
      },
      set(value) {
        this.$store.commit("SET_FORM_DOKUMEN", {
          key: "document_number",
          value,
        });
      },
    },
    document_date: {
      get() {
        return this.$store.state.dokumen.form.document_date;
      },
      set(value) {
        this.$store.commit("SET_FORM_DOKUMEN", {
          key: "document_date",
          value,
        });
      },
    },
    document_expire: {
      get() {
        return this.$store.state.dokumen.form.document_expire;
      },
      set(value) {
        this.$store.commit("SET_FORM_DOKUMEN", {
          key: "document_expire",
          value,
        });
      },
    },
    keterangan: {
      get() {
        return this.$store.state.dokumen.form.keterangan;
      },
      set(value) {
        this.$store.commit("SET_FORM_DOKUMEN", {
          key: "keterangan",
          value,
        });
      },
    },
    document_issued: {
      get() {
        return this.$store.state.dokumen.form.document_issued;
      },
      set(value) {
        this.$store.commit("SET_FORM_DOKUMEN", {
          key: "document_issued",
          value,
        });
      },
    },
    document_id: {
      get() {
        return this.$store.state.dokumen.form.document_id;
      },
      set(value) {
        this.$store.commit("SET_FORM_DOKUMEN", {
          key: "document_id",
          value,
        });
      },
    },
    file: {
      get() {
        return this.$store.state.dokumen.form.file;
      },
      set(value) {
        this.$store.commit("SET_FORM_DOKUMEN", {
          key: "file",
          value,
        });
      },
    },
  },
  methods: {
    handleOpen() {
      this.showModal = true;
    },
    handleClose() {
      this.$store.commit("RESET_FORM_DOKUMEN");
      this.showModal = false;
    },
    handleSubmit() {
      if (this.$refs.initialForm.validate()) {
        this.$store.dispatch("UploadDokumen").then((val) => {
          if (val) {
            this.handleClose();
          }
        });
      }
    },
  },
};
</script>
